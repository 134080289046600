import "./popupEdit.css";
import {PopupCheck} from "../PopupCheck";
import {useEffect, useState} from "react";
import {Button, Input, Loader, Select, Textarea} from '@mantine/core';
import jwtInterceptor from "../../shared/jwtInterceptor";
import {API, API_URL_ADMIN} from "../../config/axios.config";
export const PopupEdit = ({popup, path, item, reload, reloadValue}) => {

    const [popupPhoto, setPopupPhoto] = useState(false);
    const [number, setNumber] = useState();
    const [amount, setAmount] = useState(0);
    const [status, setStatus] = useState("На модерации")
    const [reason, setReason] = useState()
    const [error, setError] = useState(false);
    const [dup, setDuplicate] = useState(false);

    useEffect(() => {
        setNumber(item.number)
        setStatus(item.status.name)
        setReason(item.reason)
        setAmount(item.amountProduct)
    }, [])
    

    const handlerSave = () => {
        if (number !== undefined && number !== "") {
            let data = {
                "id" : item.id,
                "status": status,
                "reason": reason,
                "number": number,
                "amountProduct": amount
            }
            jwtInterceptor.post(API_URL_ADMIN + "/check/update", data).then(r => {
                if (r.data.status === "FOUND") {
                    setError("Номер уже был зарегестрирован")
                }
                else {
                    popup(false);
                    reload(!reloadValue);
                }
            });
        }else {
            setError(true);
        }
    }

    const handlerPhoto = () => {
        if (popupPhoto) {
            setPopupPhoto(false)
        }
        else {
            setPopupPhoto(true)
        }
    }

    const handlerChange = (e) => {
        setNumber(e.target.value.replace(/[^0-9]/gi, ''));
        jwtInterceptor.get(API_URL_ADMIN + "/check/validate/" + e.target.value.replace(/[^0-9]/gi, '') + "/" + item.id).then(r => {
            if (r.data.status === "FOUND") {
                setDuplicate("Номер уже был зарегистрирован")
            }
            else {
                setDuplicate(null);
            }
        }).catch(() => {
            setDuplicate("Большое значение")
        });
    }

    return (
        <>
                <div className="popup-img" onClick={e => (e.currentTarget === e.target) && popup(false)}>
                    <div className="popup-check-body">
                        <h1>Редактирование</h1>
                        <img src={API + "/image/" + path} onClick={handlerPhoto} width={popupPhoto ? "500" : "100"}
                             height={popupPhoto ? "500" : "100"} alt=""/>
                        <span>Номер</span>

                        <Input name="Номер" error={error} value={number} errorText={error}
                               onChange={(event) => handlerChange(event)}></Input>
                        <span className="error-message dup">{dup}</span>
                        <span>Количество игровых товаров</span>
                        <Input name="Количество игровых товаров" error={error} value={amount} errorText={error}
                               onChange={(event) => setAmount(event.target.value)}></Input>
                        {status}
                        <div style={{display: "flex", gap: "10px"}}>
                            <Button onClick={() => setStatus("На модерации")}>
                                На модерации
                            </Button>
                            <Button color="cyan" onClick={() => setStatus("Одобрено")}>
                                Одобрить
                            </Button>
                            <Button color="pink" onClick={() => setStatus("Отклонено")}>
                                Отклонить
                            </Button>
                        </div>

                        {status === "Отклонено" ? <>
                            <Textarea
                                style={{width: "100%"}}
                                size={15}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder="Причина"
                                label="Ваша причина"
                                withAsterisk
                            />
                            <Select
                                style={{width: "100%"}}
                                label="Выбрать причину"
                                placeholder="Пусто"
                                onChange={(e) => {
                                    setReason(e)
                                }}
                                data={[
                                    {
                                        value: 'Изображение Кассового чека отсутствует. Загрузите повторно на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким и содержать:\n' +
                                            '- сведения о номере документа;\n' +
                                            '- наименование продавца (торгового объекта, где были приобретены Игровые продукты), учетный номер плательщика (УНП);\n' +
                                            '- дату и время продажи Игровых продуктов;\n' +
                                            '- наименование Игровых продуктов;\n' +
                                            '- количество и стоимость Игровых продуктов;\n' +
                                            '- итоговую сумму покупки.\n' +
                                            'Кассовые чеки без наличия информации, указанной в настоящем пункте, либо без наличия Игровых продуктов, не могут быть использованы для участия в Рекламной игре.\n',
                                        label: 'Нет фото чека'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры.\n' +
                                            ' Покупка совершена не в установленный правилами Рекламной игры период.\n',
                                        label: 'Неверная дата чека (покупка совершена ДО начала или ПОСЛЕ окончания РИ)'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. Правил Рекламной игры. Загружена фотография не чека.',
                                        label: 'Загружен НЕ чек'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры.\n' +
                                            'В чеке отсутствуют Игровые продукты.\n',
                                        label: 'В чеке вообще нет игровых продуктов'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.1.6. Правил Рекламной игры. Загружена нечёткая фотография чека.',
                                        label: 'Плохое качество фото чека'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.5. Правил Рекламной игры. Данные из 1 (одного) Кассового чека, которые регистрирует Участник, принимаются к участию в Рекламной игре один раз.',
                                        label: 'Повторная регистрация чека одобренного'
                                    },
                                    {
                                        value: 'Ваш чек был отклонен по причине не соответствия п. 10.1.1. Правил Рекламной игры. Покупка совершена не в магазине торговой сети автозаправочных станций ИООО «ЛУКОЙЛ Белоруссия», расположенных на территории Республики Беларусь',
                                        label: 'Покупка совершена НЕ на АЗС «ЛУКОЙЛ»'
                                    },
                                ]}
                            />
                        </> : <></>}
                        <Button onClick={handlerSave} color="lime">Сохранить</Button>
                    </div>
                </div>
        </>
    )
}