import React, { useState } from 'react';
import './Accordion.css';

const Accordion = () => {
    const [activeAccordions, setActiveAccordions] = useState([]);
    const [isPerformanceCookieEnabled, setIsPerformanceCookieEnabled] = useState(true);

    const toggleAccordion = (section) => {
        if (activeAccordions.includes(section)) {
            setActiveAccordions(activeAccordions.filter(item => item !== section));
        } else {
            setActiveAccordions([...activeAccordions, section]);
        }
    };

    const togglePerformanceCookie = () => {
        setIsPerformanceCookieEnabled(!isPerformanceCookieEnabled);
    };

    return (
        <div className="toggle-panel-container">
            {/* Первая вкладка */}
            <div className="toggle-panel-section">
                <div className="toggle-panel-header" onClick={() => toggleAccordion('first')}>
                    <div className="toggle-panel-title">
                        <span className="panel-arrow">
                            {activeAccordions.includes('first') ? '▲' : '▼'}
                        </span>
                        <span className="accordion-title-text">Строго необходимые файлы cookie</span>
                    </div>
                    <div className="switch-container">
                        <span className="switch-label">Включены всегда</span>
                        <label className="switch">
                            <input type="checkbox" checked disabled />
                            <span className="slider disabled"></span>
                        </label>
                    </div>
                </div>
                {activeAccordions.includes('first') && (
                    <div className="toggle-panel-content">
                        <p>
                            Эти файлы cookie необходимы для функционирования веб-сайта и не могут быть отключены в наших системах. Как правило, они активируются только в ответ на ваши действия, аналогичные запросу услуг, таким как настройка уровня конфиденциальности, вход в систему или заполнение форм. Вы можете настроить браузер таким образом, чтобы он блокировал эти файлы cookie или уведомлял вас об их использовании, но в таком случае возможно, что некоторые разделы веб-сайта не будут работать.
                        </p>
                    </div>
                )}
            </div>

            {/* Вторая вкладка */}
            <div className="toggle-panel-section">
                <div className="toggle-panel-header" onClick={() => toggleAccordion('second')}>
                    <div className="toggle-panel-title">
                        <span className="panel-arrow">
                            {activeAccordions.includes('second') ? '▲' : '▼'}
                        </span>
                        <span className="accordion-title-text">Эксплуатационные файлы cookie</span>
                    </div>
                    <div className="switch-container">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isPerformanceCookieEnabled}
                                onChange={togglePerformanceCookie}
                            />
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
                {activeAccordions.includes('second') && (
                    <div className="toggle-panel-content">
                        <p>
                            Эти файлы cookie позволяют нам подсчитывать количество посещений и источников трафика, чтобы оценивать и улучшать работу нашего веб-сайта. Благодаря им мы знаем, какие страницы являются наиболее и наименее популярными, и видим, каким образом посетители перемещаются по веб-сайту. Все данные, собираемые при помощи этих cookie, группируются в статистику, а значит, являются анонимными. Если вы не одобрите использование этих файлов cookie, у нас не будет данных о посещении вами нашего веб-сайта.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Accordion;
