import { useContext } from "react";
import { Navigate } from "react-router-dom";

import AuthContext from "./AuthContext";

export const ProtectedRoute = ({ children, accessBy }) => {

  const { user } = useContext(AuthContext);
  if (accessBy === "non-authenticated") {
    if (!user) {
      return children;
    }
  } else if (accessBy === "authenticated") {
    if (user) {
      return children;
    }
  } else if (accessBy === "admin") {
    if (user && user.role === "ROLE_ADMIN") {
      return children;
    }
  }

  return <Navigate to="/"></Navigate>;
};

