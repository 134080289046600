import React from 'react';
import './PopUpAfterEmail.css'

function PopUpAfterEmail({setIsAfterEmailOpen}) {
    return (
        <div>
            <div className="PopUpAfterEmail">
                <div className="PopUpAfterEmail__container">
                    <div className="PopUpAfterEmail-title">
                        <p>
                            Регистрация завершена!
                        </p>
                    </div>
                    <div className="PopUpAfterEmail-button" id='red-button'>
                        <span onClick={() => setIsAfterEmailOpen(false)}>ВХОД</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopUpAfterEmail;