import React from 'react';
import './PopUpBadPass.css'

function PopUpBadPass(props) {
    return (
        <div>
            <div className="PopUpAfterEmail">
                <div className="PopUpAfterEmail__container">
                    <div className="PopUpAfterEmail-title">
                        <p>
                            Ой, продолжайте жевать!
                        </p>
                    </div>
                    <div className="PopUpBadPass-description">
                        <p>
                            Неверный логин или пароль
                            попробуйте еще раз
                        </p>
                    </div>
                    <div className="PopUpAfterEmail-button" id='red-button'>
                        <a href="">ВХОД</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopUpBadPass;