import React, {useContext, useEffect, useState} from 'react';
import "./home.css"
import "./faq.js"
import Popup from "../Popup/Popup";
import Cookies from "../Modals/Cookies/Cookies";
import Agreements from "../Modals/Registration/Agreements/Agreements";
import RegistrationForm from "../Modals/Registration/Registration-form/UniqueRegistrationForm";
import UniqueAccordion from "../AccordionFaq/AccordionFaq";
import UniqueRegistrationForm from "../Modals/Registration/Registration-form/UniqueRegistrationForm";
import AuthContext from "../../shared/AuthContext";
import PopUpCheckEmail from "../Modals/Registration/PopUpCheckEmail/PopUpCheckEmail";
import PopUpAfterEmail from "../Modals/Registration/PopUpAfterEmail/PopUpAfterEmail";
import PopUpBadPass from "../Modals/Registration/PopUpBadPass/PopUpBadPass";
import {useParams} from "react-router-dom";
import jwtInterceptor from "../../shared/jwtInterceptor";
import {API, API_URL, API_URL_USER} from "../../config/axios.config";
import ForgotPass from "../Modals/Registration/ForgotPass/ForgotPass";
import NewForgotPass from "../Modals/Registration/NewForgotPass/NewForgotPass";
import NewForgotPassSuccess from "../Modals/Registration/NewForgotPassSuccess/NewForgotPassSuccess";
import axios from "axios";

function Home(props) {
    // Состояние для управления первым попапом
    const {login, logout, user} = useContext(AuthContext);
    const {code} = useParams();
    const {recoverCode} = useParams();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [error, setError] = useState(false);

    const [userData, setUserData] = useState({
        "email": "",
        "password": "",
    })
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const [isAgreementsOpen, setIsAgreementsOpen] = useState(false);
    const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
    const [isCheckEmailOpen, setIsCheckEmailOpen] = useState(false);
    const [isAfterEmailOpen, setIsAfterEmailOpen] = useState(false); // Состояние для PopUpAfterEmail
    const [isBadPassOpen, setIsBadPassOpen] = useState(false); // Состояние для PopUpBadPass
    const [isForgotPassOpen, setIsForgotPassOpen] = useState(false);
    const [isNewForgotPassOpen, setIsNewForgotPassOpen] = useState(false);
    const [isNewForgotPassSuccessOpen, setIsNewForgotPassSuccessOpen] = useState(false);


    const handleNewForgotPassSuccessOpen = () => {
        setIsNewForgotPassSuccessOpen(true);
    };


    const handleForgotPassOpen = () => {
        setIsNewForgotPassOpen(true);
    };

    // Функция для открытия попапа с согласием
    const handleOpenAgreements = () => {
        setIsAgreementsOpen(true);
    };

    // Открытие попапа восстановления пароля
    const handleOpenForgotPass = () => {
        setIsForgotPassOpen(true);
    };

    // Функция для открытия попапа с формой регистрации
    const handleOpenRegistration = () => {
        setIsRegistrationOpen(true);
        setIsAgreementsOpen(false); // Закрыть попап согласия
    };

    const handleOpenCheckEmail = () => {
        setIsCheckEmailOpen(true);
    };

    const handleOpenAfterEmail = () => {
        setIsAfterEmailOpen(true);
    };

    const handleOpenBadPass = () => {
        setIsBadPassOpen(true);
    };

    useEffect(() => {
        if (user && user.everify === "false") {
            setIsCheckEmailOpen(true);
            logout();
        }
    }, [user]);

    useEffect(() => {
        if (recoverCode) {
            axios.get(`${API}/validate/recover/${recoverCode}`).then(response => {
                if(response.data.data === true) {
                    setIsNewForgotPassOpen(true);
                }
            })
        }
    })

    useEffect(() => {
        if (code) {
            jwtInterceptor.get(API + "/activate?code=" + code).then(r => {
                if(r.data.data === "Activated") {
                    setIsCheckEmailOpen(false);
                    setIsAfterEmailOpen(true);
                }
            })
        }
    }, [code])

    useEffect(() => {
        setError(false);
    }, [userData])

    return (
        <div>
            <div className="home">
                <div className="container">
                    <div id="triangle-bottomleft" className="container-figure">
                        <div className="first-page">
                            <div className="first-page-desc">
                                <img src={require("../../img/test.png")} alt="" className="first-page-img-desc"/>
                            </div>
                            <div className="first-page-mob">
                                <div className="first-page-mob-number"><a href="tel:+375445572730">+375 (44) 557-27-30</a></div>
                                <div className="first-page-mob-title"><p>ВЫИГРЫВАЙ <br/>СО ВКУСОМ!</p></div>
                                <div className="first-page-mob-description"><p>с 30 сентября по 24 ноября 2024 года</p>
                                </div>
                                <img src={require("../../img/Group 8141.png")} alt="" className="first-page-img-mob"/>
                                <div className="first-page-text">
                                    <p>БОЛЬШЕ покупок - <br/>БОЛЬШЕ шансов <br/>на выигрыш</p>
                                </div>
                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="how-to-play">
                <hr/>
                <div className="container">
                    <div className="how-to-play__container">
                        <div className="how-to-play__question">
                            <div className="how-to-play__question__text">
                                Как участвовать в рекламной игре?
                            </div>
                            <div className="how-to-play__question__buttons">
                                <button className="how-to-play__rules"><a target='_blank' href="/files/RULES-dirol.pdf" download='RULES-dirol.pdf'>ПРАВИЛА</a></button>
                                <button  className="how-to-play__users"><a href="#registrationUser">УЧАСТВОВАТЬ</a></button>
                            </div>
                        </div>
                        <div className="how-to-play__steps">
                            <div className="how-to-play__step-1" id='how-to-play__step'>
                                <div className="how-to-play__step-img">
                                    <img src={require("../../img/1.png")} alt="" className="step1"/>

                                </div>
                                <div className="how-to-play__step-text">
                                    <div className="how-to-play__step-text-title">
                                        КУПИ
                                    </div>
                                    <div className="how-to-play__step-text-description">
                                        Dirol на АЗС «ЛУКОЙЛ», расположенных
                                        на территории Республики Беларусь
                                    </div>
                                </div>
                            </div>
                            <div className="how-to-play__step-2" id='how-to-play__step'>
                                <div className="how-to-play__step-img">
                                    <img src={require("../../img/2.png")} alt="" className="step2"/>

                                </div>
                                <div className="how-to-play__step-text">
                                    <div className="how-to-play__step-text-title">
                                        ЗАРЕГИСТРИРУЙСЯ
                                    </div>
                                    <div className="how-to-play__step-text-description">
                                        на сайте
                                        www.lukoil-promo.by
                                    </div>
                                </div>
                            </div>
                            <div className="how-to-play__step-3" id='how-to-play__step'>
                                <div className="how-to-play__step-img">
                                    <img src={require("../../img/3.png")} alt="" className="step3"/>

                                </div>
                                <div className="how-to-play__step-text">
                                    <div className="how-to-play__step-text-title">
                                        ЗАГРУЗИ
                                    </div>
                                    <div className="how-to-play__step-text-description">
                                        фото чека
                                    </div>
                                </div>
                            </div>
                            <div className="how-to-play__step-4" id='how-to-play__step'>
                                <div className="how-to-play__step-img">
                                    <img src={require("../../img/4.png")} alt="" className="step4"/>

                                </div>
                                <div className="how-to-play__step-text">
                                    <div className="how-to-play__step-text-title">
                                        ВЫИГРЫВАЙ
                                    </div>
                                    <div className="how-to-play__step-text-description">
                                        ПРИЗЫ
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="prize">
                <div className="container">
                    <div className="prize_text">ПРИЗЫ</div>
                    <img src={require("../../img/Group 8135.png")} alt="" className="prize-img-desk"/>
                    <img src={require("../../img/Group 8002.png")} alt="" className="prize-img-mob"/>
                </div>
            </div>
            {!user &&
                <div className="home-enter" id='registrationUser'>
                    <div className="container">
                        <div className="home-enter__container">
                            <div className="home-enter__title">ВХОД В ЛИЧНЫЙ КАБИНЕТ</div>
                            <div className="home-enter__description">Для загрузки чека зарегистрируйтесь
                                или войдите в свой личный кабинет
                            </div>
                            <div className='home-enter-form-container'>
                                {error && <span className="unique-error-message">{error}</span>}
                                <input placeholder='email' value={userData.email} onChange={(e) => {
                                    setUserData({...userData, email: e.target.value })
                                }} type="email" name="name" id="form-email" className={error && 'unique-input-error'} required/>
                                <input placeholder='пароль' value={userData.password} className={error && 'unique-input-error'} onChange={(e) => {
                                    setUserData({...userData, password: e.target.value })
                                }} type="password" name="name" id="form-name" required/>
                                <input type="checkbox" name="remember-me" className="remember-me a11y-hidden"
                                       id="remember-me" tabIndex="3"/>
                                <label className="label-remember-me" htmlFor="remember-me">
                                    <span>Запомнить меня</span>
                                </label>
                                <div className="forgot-email">
                                    <a href="#" onClick={() => setIsForgotPassOpen(true)}>Забыли пароль?</a>
                                </div>
                                <input type="submit" onClick={() => login(userData, setError)} className="sing-in" value="Войти"/>
                                <div>
                                    <button className='sing-up' onClick={handleOpenAgreements}>ЗАРЕГИСТРИРОВАТЬСЯ</button>
                                    <Agreements
                                        isOpen={isAgreementsOpen}
                                        onClose={() => setIsAgreementsOpen(false)}
                                        onAgree={handleOpenRegistration}
                                    />

                                    <Popup isOpen={isRegistrationOpen} onClose={() => setIsRegistrationOpen(false)}>
                                        <UniqueRegistrationForm setShowPopup={setIsRegistrationOpen} setIsCheckEmailOpen={setIsCheckEmailOpen}/>
                                    </Popup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <UniqueAccordion/>
            <Cookies/>
            <Popup isOpen={isPopupOpen} onClose={closePopup}>
                <RegistrationForm />
            </Popup>

            <div>
                {/* Your existing content */}

                {/* PopUpCheckEmail component */}
                <Popup isOpen={isCheckEmailOpen} onClose={() => setIsCheckEmailOpen(false)}>
                    <PopUpCheckEmail />
                </Popup>

                <Popup isOpen={isAfterEmailOpen} onClose={() => setIsAfterEmailOpen(false)}>
                    <PopUpAfterEmail setIsAfterEmailOpen={setIsAfterEmailOpen}/>
                </Popup>

                <Popup isOpen={isBadPassOpen} onClose={() => setIsBadPassOpen(false)}>
                    <PopUpBadPass />
                </Popup>
            </div>

            {/* Попап восстановления пароля */}
            <Popup isOpen={isForgotPassOpen} onClose={() => setIsForgotPassOpen(false)}>
                <ForgotPass/>
            </Popup>
            <Popup isOpen={isNewForgotPassOpen} onClose={() => setIsNewForgotPassOpen(false)}>
                <NewForgotPass thisPopup={setIsNewForgotPassOpen} setIsAfterEmailOpen={setIsNewForgotPassSuccessOpen} code={recoverCode}/>
            </Popup>

            <Popup isOpen={isNewForgotPassSuccessOpen} onClose={() => setIsNewForgotPassSuccessOpen(false)}>
              <NewForgotPassSuccess/>
            </Popup>
        </div>
    );
}

export default Home;
