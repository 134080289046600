import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import React from "react";
import CheckStatus from "../../../CheckStatus/CheckStatus";

export const RvCheck = () => {

    return (
        <div className={`tab-content active`} id="content-3">
            <div className="checks">

                    <CheckStatus/>

            </div>
        </div>
    )
}