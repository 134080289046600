import React, {useContext, useState} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import './App.css';

import Home from "./components/home/home";
import PersonalAccount from "./components/Personal-account/personal-account";
import Agreements from "./components/Modals/Registration/Agreements/Agreements";
import {Layout} from "./layout/index.layout";
import {Profile} from "./components/Personal-account/profile/profile";
import {RvCheck} from "./components/Personal-account/rv-check/rv-check";
import {UploadCheck} from "./components/Personal-account/upload-check/upload-check";
import AuthContext, {AuthContextProvider} from "./shared/AuthContext";
import {ProtectedRoute} from "./shared/ProtectedRoute";
import {Dashboard} from "./admin/Dashboard";
import {Checks} from "./admin/Checks";
import {Users} from "./admin/Users";
import Winners from "./components/Winners/Winners";
import {AcceptedPage} from "./admin/Accepted/accepted.page";



function App() {
    const [isAgreementOpen, setIsAgreementOpen] = useState(false);


    // Закрыть попап
    const closeAgreementPopup = () => {
        setIsAgreementOpen(false);
    };

    // Обработка согласия
    const handleAgree = () => {
        alert('Согласие получено');
        closeAgreementPopup();
    };

    return (
        <AuthContextProvider>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path=":code" element={<Home/>}/>
                    <Route path="/winners" element={<Winners/>}/>
                    <Route path="/recover/:recoverCode" element={<Home/>}/>
                    <Route path="/personal-account" element={
                        <ProtectedRoute accessBy="authenticated">
                            <PersonalAccount/>
                        </ProtectedRoute>
                    }>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="check" element={<RvCheck/>}/>
                        <Route index element={<UploadCheck/>}/>
                    </Route>
                </Route>
                <Route path="/admin/dashboard" element={
                    <ProtectedRoute accessBy="admin">
                        <Dashboard/>
                    </ProtectedRoute>
                }>
                    <Route index element={
                        <ProtectedRoute accessBy="admin">
                            <Checks/>
                        </ProtectedRoute>}>
                    </Route>
                    <Route path="/admin/dashboard/users" element={
                        <ProtectedRoute accessBy="admin">
                            <Users/>
                        </ProtectedRoute>}>
                    </Route>
                    <Route path="/admin/dashboard/user/:id" element={
                        <ProtectedRoute accessBy="admin">
                            <Checks/>
                        </ProtectedRoute>}>
                    </Route>
                    <Route path="/admin/dashboard/accepted" element={
                        <ProtectedRoute accessBy="admin">
                            <AcceptedPage/>
                        </ProtectedRoute>}>
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Agreements
                isOpen={isAgreementOpen}
                onClose={closeAgreementPopup}
                onAgree={handleAgree}
            />
        </AuthContextProvider>
    );
}

export default App;
