import {useEffect, useState} from "react";
import jwtInterceoptor from "../../shared/jwtInterceptor";
import "./Users.css";
import {Input, Modal, Select} from '@mantine/core';
import { Button } from '@mantine/core';
import {
    ActionIcon,
    Anchor,
    Avatar,
    Badge,
    Group,
    Loader, Pagination,
    ScrollArea,
    Table,
    Text,
    useMantineTheme
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {API_URL_ADMIN} from "../../config/axios.config";
import { IconGift } from '@tabler/icons-react';

const jobColors = {
    "true": 'cyan',
    "null": 'pink',
};


export const Users = () => {

    const [data, setData] = useState([]);
    const [activePage, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState();
    const [type, setType] = useState();
    const [error, setError] = useState();
    const [opened, setOpened] = useState(false);
    const navigator = useNavigate();
    const [user, setUser] = useState();
    const [riffle, setRiffle] = useState();
    const [prize, setPrize] = useState();

    useEffect(() => {
        jwtInterceoptor.get(API_URL_ADMIN + "/users/get/all/" + activePage).then(r => {
            setMaxPage(r.data.data.maxPage);
            setData(r.data.data.data);
        })
    }, []);

    const theme = useMantineTheme();

    const gift = () => {
        let body = {
            "name" : prize,
            "user" : {
                "id" : user.id,
            },
            "riffle" : {
                "id" : riffle
            }
        }
        jwtInterceoptor.post(API_URL_ADMIN + "/riffle/save", body).then(r => {
            setOpened(false);
        })
    }

    const rows = data.map((item) => { return (
        <tr className="users-tr" key={item.id}>
            <td onClick={() => {navigator("/admin/dashboard/user/" + item.id)}}>
                <Group spacing="sm">
                    <Avatar size={30} radius={30} />
                    <Text size="sm" weight={500} >
                        {`${item.profile.surname} ${item.profile.name} ${item.profile.lastName !== null ? item.profile.lastname : ""}` }
                    </Text>
                </Group>
            </td>
            <td>
                <Anchor size="sm" href="#" onClick={(event) => event.preventDefault()}>
                    {item.email}
                </Anchor>
            </td>
            <td>
                <Badge
                    color={jobColors[item.emailVerify]}
                    variant={theme.colorScheme === 'dark' ? 'light' : 'outline'}
                >
                    {item.emailVerify ? "Да" : "Нет"}
                </Badge>
            </td>
            <td>
                <Text size="sm" color="dimmed">
                    {item.phoneNumber}
                </Text>
            </td>
            <td>
                <Group spacing={0} position="right">
                    <ActionIcon onClick={() => {setOpened(true); setUser(item)}}>
                        <IconGift size={16} stroke={1.5} />
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    )})

    const changePage = (e) => {
        setLoading(true);
        setPage(e);
        console.log(value)
        if(value !== undefined && value !== "") {
            jwtInterceoptor.get(API_URL_ADMIN + `/users/search/${type}/${value}/${activePage}`).then(r => {
                setMaxPage(r.data.data.maxPage);
                setData(r.data.data.data);
                setLoading(false);
            })
        }
        else {
            jwtInterceoptor.get(API_URL_ADMIN + "/users/get/all/" + e).then(r => {
                setMaxPage(r.data.data.maxPage);
                setData(r.data.data.data);
                setLoading(false);
            })
        }
    }

    const handlerInput = (e) => {
        setValue(e.target.value);
        if (type) {
            if (e.target.value !== "") {
                setError();
                setPage(1)
                jwtInterceoptor.get(API_URL_ADMIN + `/users/search/${type}/${e.target.value}/${activePage}`).then(r => {
                    if (r.data.data.data !== null) {
                        setMaxPage(r.data.data.maxPage);
                        setData(r.data.data.data);
                    }
                    setLoading(false);
                })
            }
            else {
                jwtInterceoptor.get(API_URL_ADMIN + "/users/get/all/" + activePage).then(r => {
                    setMaxPage(r.data.data.maxPage);
                    setData(r.data.data.data);
                })
            }
        }
        else {
            setError("Не выбрано поле для поиска")
        }
    }

    return (
        <>
            <div className="check-body">
                <div style={{width: "100%", display: "flex", justifyContent: "center", gap: "30px"}}>
                    <Select
                        onChange={(e) => setType(e)}
                        label="Поле для поиска"
                        placeholder="Выбор..."
                        data={[
                            { value: 'surname', label: 'Фамилия' },
                            { value: 'email', label: 'Почта' },
                            { value: 'phone', label: 'Номер телефона' },
                            { value: 'id', label: 'Id' },
                        ]}
                    />
                    <div style={{width: "300px"}}>
                        <Input
                            value={value}
                            onChange={(e) => handlerInput(e)}
                            error={error}
                            errorText={error}
                        />
                    </div>

                </div>
                <ScrollArea>
                    <Table  verticalSpacing="sm">
                        <thead>
                        <tr>
                            <th>Участник</th>
                            <th>Почта</th>
                            <th>Подтвержден</th>
                            <th>Телефон</th>
                        </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </Table>
                </ScrollArea>

                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {loading ?
                        <Loader color="yellow" variant="dots" />
                        :
                        <Pagination page={activePage} onChange={changePage} total={maxPage} color="yellow" size="lg" />
                    }
                </div>
            </div>
        </>
    );
}