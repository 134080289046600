import React, {useState} from 'react';
import Popup from "../../Popup/Popup";
import './PhotoUploadedSuccessfully.css';

function PhotoUploadedSuccessfully(props) {
    const [isChecked, setIsChecked] = useState(false); // Состояние чекбокса

    // Обработчик клика по кнопке
    const handleAgreeClick = () => {
        if (isChecked) {
            // Если чекбокс выбран, выполняем действие
            props.onAgree();
        }
    };

    // Обработчик изменения чекбокса
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Popup isOpen={props.isOpen} onClose={props.onClose}>
            <div className="photoUploadedSuccessfully">
                <div className="photoUploadedSuccessfully__container">
                    <div className="photoUploadedSuccessfully_title">
                        <p>
                            Фото успешно загружено
                            и отправлено на модерацию
                        </p>

                    </div>
                </div>
            </div>
        </Popup>
    );
}

export default PhotoUploadedSuccessfully;
