import "./dashboard.css";
import {useContext, useState} from 'react';
import {Navbar, Center, Tooltip, UnstyledButton, createStyles, Stack, Modal, LoadingOverlay} from '@mantine/core';
import {Popover, Text, Button} from '@mantine/core';
import {
    TablerIcon,
    IconHome2,
    IconGauge,
    IconDeviceDesktopAnalytics,
    IconFingerprint,
    IconCalendarStats,
    IconUser,
    IconSettings,
    IconLogout,
    IconSwitchHorizontal, IconCheck,
} from '@tabler/icons-react';
import {Outlet, useNavigate} from "react-router-dom";
import AuthContext from "../../shared/AuthContext";
import jwtInterceoptor from "../../shared/jwtInterceptor";
import {API_URL_ADMIN, CONFIGFETCH} from "../../config/axios.config";

const useStyles = createStyles((theme) => ({
    primaryColor: "rgb(232, 89, 12)",
    link: {
        width: 50,
        height: 50,
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.white,
        opacity: 0.85,

        '&:hover': {
            opacity: 1,
            backgroundColor: theme.fn.lighten("rgb(240, 140, 0)", 0.1),
        },
    },

    active: {
        opacity: 1,
        '&, &:hover': {
            backgroundColor: theme.fn.lighten("rgb(240, 140, 0)", 0.15),
        },
    },
}));


function NavbarLink({icon: Icon, label, active, onClick}) {
    const {classes, cx} = useStyles();
    return (
        <Tooltip label={label} position="right" transitionDuration={0}>
            <UnstyledButton onClick={onClick} className={cx(classes.link, {[classes.active]: active})}>
                <Icon stroke={1.5}/>
            </UnstyledButton>
        </Tooltip>
    );
}

const mockdata = [
    {icon: IconHome2, label: 'Главная', link: "/", over: false},
    {icon: IconGauge, label: 'Чеки', link: "/admin/dashboard", over: false},
    {icon: IconCalendarStats, label: 'Отчеты', over: true},
    {icon: IconUser, label: 'Аккаунты', link: "/admin/dashboard/users", over: false},
    {icon: IconCheck, label: 'Одобренные чеки', link: "/admin/dashboard/accepted", over: false},
];


export const Dashboard = () => {

    const [active, setActive] = useState(1);
    const navigator = useNavigate("/");
    const [opened, setOpened] = useState(false);
    const {logout} = useContext(AuthContext);
    const [visible, setVisible] = useState(false);

    const links = mockdata.map((link, index) => {
        if (link.over) {
            return (
                <NavbarLink
                    {...link}
                    key={link.label}
                    active={index === active}
                    onClick={() => setOpened((o) => !o)}
                />
            )
        } else {
            return (
                <NavbarLink
                    {...link}
                    key={link.label}
                    active={index === active}
                    onClick={() => {
                        setActive(index);
                        navigator(link.link)
                    }}
                />
            )
        }
    });

    const downloadUsers = () => {
        setVisible(true)
        fetch(API_URL_ADMIN + "/users/report", CONFIGFETCH).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Выгрузка-Участников.xlsx";
                a.click();
                setVisible(false)
            });
        })
    }

    const downloadChecks = () => {
        setVisible(true)
        fetch(API_URL_ADMIN + "/users/report/check", CONFIGFETCH).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Выгрузка-Чеков.xlsx";
                a.click();
                setVisible(false)
            });
        })
    }

    const downloadAcceptedChecks = () => {
        setVisible(true)
        fetch(API_URL_ADMIN + "/users/report/check/accepted", CONFIGFETCH).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Выгрузка-Одобренных-Чеков.xlsx";
                a.click();
                setVisible(false)
            });
        })
    }

    return (
        <div className="admin-body">

            <Navbar
                width={{base: 80}}
                p="md"
                sx={(theme) => ({
                    backgroundColor: "rgb(240, 140, 0)",
                })}
            >
                <Center>
                   {/* <MantineLogo type="mark" inverted size={30}/>*/}
                </Center>
                <Navbar.Section grow mt={50}>
                    <Stack justify="center" spacing={0}>
                        {links}
                    </Stack>
                </Navbar.Section>
                <Navbar.Section>
                    <Stack justify="center" spacing={0}>
                        <NavbarLink onClick={logout} icon={IconLogout} label="Logout"/>
                    </Stack>
                </Navbar.Section>
            </Navbar>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title="Выбор выгрузки"
            >

                <div style={{width: 400, position: 'relative'}}>
                    <LoadingOverlay visible={visible} overlayBlur={2}/>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Button color="pink" onClick={downloadUsers}>
                            Все участники
                        </Button>
                        <Button color="green" onClick={downloadAcceptedChecks}>
                            Одобренные чеки
                        </Button>
                        <Button color="yellow" onClick={downloadChecks}>
                            Все чеки
                        </Button>
                    </div>
                </div>
            </Modal>
            <Outlet/>
        </div>
    )
}