import React, {useState, useEffect} from 'react';
import './CheckStatus.css';
import jwtInterceoptor from "../shared/jwtInterceptor";
import {API_URL_USER} from "../config/axios.config";
import {formatDate} from "../components/Personal-account/profile/profile";


export function formatDateString(dateString) {
    const date = new Date(dateString);

    // Получаем компоненты даты
    const day = String(date.getDate()).padStart(2, '0'); // ДД
    const month = String(date.getMonth() + 1).padStart(2, '0'); // ММ (месяцы начинаются с 0)
    const year = date.getFullYear(); // ГГГГ
    const hours = String(date.getHours()).padStart(2, '0'); // ЧЧ
    const minutes = String(date.getMinutes()).padStart(2, '0'); // ММ

    // Форматируем строку
    return `${day}-${month}-${year} ${hours}:${minutes}`;
}

function getNextDate(date) {
    const inputDate = new Date(date);

    const dateRanges = [
        { start: new Date('2024-09-30T00:00:00'), end: new Date('2024-10-06T23:59:59'), nextDate: '2024-10-11T12:30:00' },
        { start: new Date('2024-10-07T00:00:00'), end: new Date('2024-10-13T23:59:59'), nextDate: '2024-10-18T12:30:00' },
        { start: new Date('2024-10-14T00:00:00'), end: new Date('2024-10-20T23:59:59'), nextDate: '2024-10-25T12:30:00' },
        { start: new Date('2024-10-21T00:00:00'), end: new Date('2024-10-27T23:59:59'), nextDate: '2024-11-01T12:30:00' },
        { start: new Date('2024-09-30T00:00:00'), end: new Date('2024-10-27T23:59:59'), nextDate: '2024-11-01T13:00:00' },
        { start: new Date('2024-10-28T00:00:00'), end: new Date('2024-11-03T23:59:59'), nextDate: '2024-11-05T12:30:00' },
        { start: new Date('2024-11-04T00:00:00'), end: new Date('2024-11-10T23:59:59'), nextDate: '2024-11-15T12:30:00' },
        { start: new Date('2024-11-11T00:00:00'), end: new Date('2024-11-17T23:59:59'), nextDate: '2024-11-22T12:30:00' },
        { start: new Date('2024-11-18T00:00:00'), end: new Date('2024-11-24T23:59:59'), nextDate: '2024-11-29T12:30:00' },
        { start: new Date('2024-10-28T00:00:00'), end: new Date('2024-11-24T23:59:59'), nextDate: '2024-11-29T13:00:00' },
        { start: new Date('2024-09-30T00:00:00'), end: new Date('2024-11-24T23:59:59'), nextDate: '2024-11-29T13:30:00' },
    ];

    const matchingDates = [];

    dateRanges.forEach(range => {
        if (inputDate >= range.start && inputDate <= range.end) {
            matchingDates.push(range.nextDate);
        }
    });
    return matchingDates;
}


const CheckStatus = () => {
    const [checks, setChecks] = useState([]);

    useEffect(() => {
        jwtInterceoptor.get(`${API_URL_USER}/check/uploaded`).then(x => {
            if (x.data !== []) {
                setChecks(x.data.data);
            }
        })
    }, []);

    return (
        <div className="check-container">
            <h2 className="check-header">Статус моих чеков</h2>
            <div className="desktop-table">
                <table className="check-table">
                    <thead>
                    <tr>
                        <th>Дата загрузки</th>
                        <th>Дата розыгрыша</th>
                        <th>Статус чека</th>
                        <th>Игровой код</th>
                        <th>Причина отклонения</th>
                    </tr>
                    </thead>
                    <tbody>
                    {checks.map((check, index) => {
                        // Check the status of each check
                        if (check.status.id !== 1) {
                            // If status is not equal to 1, render a single row
                            return (
                                <tr key={index}>
                                    <td>{formatDate(check.dateLoad)}</td>
                                    <td>-----</td>
                                    <td className={
                                        check.status.id === 3 ? 'status-mob' :
                                            check.status.id === 1 ? 'status-approved' : 'status-rejected'
                                    }>
                                        {check.status.name}
                                    </td>
                                    <td>{check.gameCode ? check.gameCode.toString().padStart(7, '0') : '-----'}</td>
                                    <td>{check.reason ? check.reason : '-----'}</td>
                                </tr>
                            );
                        } else {
                            // If status equals to 1, map through products to render each product
                            return check.products.map((product, productIndex) => (
                                <tr key={`${index}-${productIndex}`}>
                                    <td>{formatDate(check.dateLoad)}</td>
                                    <td>{getNextDate(check.dateLoad)?.map(formatDateString).join(",\n")}</td>
                                    <td className={
                                        check.status.id === 3 ? 'status-mob' :
                                            check.status.id === 1 ? 'status-approved' : 'status-rejected'
                                    }>
                                        {check.status.name}
                                    </td>
                                    <td>{product.gameCode ? product.gameCode.toString().padStart(7, '0') : '-----'}</td>
                                    <td>{check.reason ? check.reason : '-----'}</td>
                                </tr>
                            ));
                        }
                    })}
                </tbody>
                </table>
            </div>
            <div className="mobile-cards">
                {checks.length > 0 && checks.map((check, index) => {
                    if (check.status.id !== 1) {
                        return (
                            <div className="card" key={index}>
                                <p><strong>Дата загрузки:</strong>
                                    <p>{formatDate(check?.dateLoad)}</p></p>
                                <p><>Дата розыгрыша:</>
                                    <p>----</p></p>
                                <p className={check?.status?.id === 3 ? 'status-mob' : check?.status?.id === 1 ? 'status-approved' : 'status-rejected'}>
                                    <strong>Статус чека:</strong>
                                    <p>{check?.status?.name}</p>
                                </p>
                                <p><strong>Игровой код:</strong> {check?.gameCode ? check?.gameCode : '-----'}</p>
                                {check?.reason && (
                                    <p><strong>Причина отклонения:</strong> <p>{check?.reason}</p></p>
                                )}
                            </div>
                        )
                    }
                    else {
                        return check.products.map((product, productIndex) => (
                            <div className="card" key={`${index}-${productIndex}`}>
                                <p><strong>Дата загрузки:</strong>
                                    <p>{formatDate(check?.dateLoad)}</p></p>
                                <p><>Дата розыгрыша:</>
                                    <p>{getNextDate(check.dateLoad)?.map(formatDateString).join(",\n")}</p></p>
                                <p className={check?.status?.id === 3 ? 'status-mob' : check?.status?.id === 1 ? 'status-approved' : 'status-rejected'}>
                                    <strong>Статус чека:</strong>
                                    <p>{check?.status?.name}</p>
                                </p>
                                <p><strong>Игровой код:</strong> {product?.gameCode ? product.gameCode.toString().padStart(7, '0') : '-----'}</p>
                                {check?.reason && (
                                    <p><strong>Причина отклонения:</strong> <p>{check?.reason}</p></p>
                                )}
                            </div>))
                    }
                })}
            </div>
        </div>
    );
};

export default CheckStatus;
