import React, {useContext, useState} from 'react';
import "./personal-account.css";
import PhotoUpload from "../PhotoUpload/PhotoUpload";
import CheckStatus from "../../CheckStatus/CheckStatus";
import AuthContext from "../../shared/AuthContext";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import {CustomNavLink} from "../CustomNavLink/CustomNavLink";

function PersonalAccount() {

    const {logout, user} = useContext(AuthContext);
    const navigation = useNavigate();

    const dropAuth = () => {
        logout()
        navigation("/")
    }

    return (
        <div>
            {user && user.everify === "true" ?
                <div className="lk">
                    <div className="container">
                        <div className="lk__title">ЛИЧНЫЙ КАБИНЕТ</div>
                        <div className="lk__description">Загрузить чеки можно по 24 ноября 2024 года включительно</div>
                        <div className="lk-tab">
                            <div className="tab">
                                <Outlet/>
                                <div className="tab-nav">
                                    <div className="lk-tabs">
                                        <CustomNavLink to="profile" title="Мой профиль"/>
                                        <CustomNavLink to="" title="Загрузка чека"/>
                                        <CustomNavLink to="check" title="Мои чеки"/>
                                    </div>
                                    <div className="log-out-tab">
                                        <button onClick={dropAuth} className="log-out-link">ВЫЙТИ</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>

                </div>
            }
        </div>
    );
}

export default PersonalAccount;
