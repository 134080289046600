import "./popupCheck.css";
import {API} from "../../config/axios.config";
export const PopupCheck = ({path, popup}) => {
    return (
        <div className="popup-img" onClick={e => (e.currentTarget === e.target) && popup(false)}>
            <div className="popup-check-body">
                <img src={API+ "/image/" + path} width="500" height="500" alt=""/>
            </div>
        </div>
    )
}