import React, { useState, useEffect } from 'react';
import './Popup.css';  // Импорт файла стилей

const Popup = ({ isOpen, onClose, title, children }) => {
    const [isAnimating, setIsAnimating] = useState(isOpen);

    // Эффект для управления анимацией при изменении isOpen
    useEffect(() => {
        if (isOpen) {
            setIsAnimating(true);
            document.body.classList.add('body-no-scroll'); // Запрет прокрутки
        } else {
            setTimeout(() => setIsAnimating(false), 300); // Задержка для завершения анимации закрытия
            document.body.classList.remove('body-no-scroll'); // Включение прокрутки
        }
    }, [isOpen]);

    // Если попап не открыт и анимация завершена, не отображаем его
    if (!isOpen && !isAnimating) {
        return null;
    }

    return (
        <div className="overlay">
            <div className={`popup ${isOpen ? 'open' : ''}`}>
                {/* Кнопка закрытия */}
                <button onClick={onClose} className="close-button">
                    &times;
                </button>
                {/* Заголовок и контент */}
                {title && <h2>{title}</h2>}
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Popup;
