import React, { useState } from 'react';
import './Cookies.css';
import Popup from "../../Popup/Popup";
import Accordion from "../../Accordion/Accordion";

const Cookies = () => {
    const [isFirstPopupOpen, setIsFirstPopupOpen] = useState(false);
    const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);

    const openFirstPopup = () => setIsFirstPopupOpen(true);
    const closeFirstPopup = () => setIsFirstPopupOpen(false);

    const openSecondPopup = () => {
        closeFirstPopup();  // Закрываем первый попап
        setIsSecondPopupOpen(true);  // Открываем второй попап
    };

    const closeSecondPopup = () => setIsSecondPopupOpen(false);

    return (
        <div className='cookie-icon'>
            {/* Изображение, на которое нажимают для открытия попапа */}
            <img
                src={require("../../../img/Vector.png")}
                alt="Cookies"
                className="home-cookies-image"
                onClick={openFirstPopup} // Открываем попап при клике
            />

            <Popup isOpen={isFirstPopupOpen} onClose={closeFirstPopup}>
                <div className="cookies-first">
                        <div className="cookies-first-container">
                            <div className="cookies-first-title">
                                Контролируйте свою конфиденциальность
                            </div>
                            <div className="cookies-first-description">
                                Компания ЧРУП «Промофреш» и наши Партнёры (далее “Мы”) используют файлы cookie,
                                чтобы управлять веб-сайтом, показывать персонализированный контент и решать
                                бизнес-задачи. Подробнее о том, как мы используем файлы cookie, рассказано
                                ниже. Вы можете разрешить использование всех файлов cookie или только
                                определенных, или же отказаться ото всех. Узнайте больше на страницах
                                Политика cookie и Положение о конфиденциальности.
                            </div>
                            <div className="cookies-first-buttons">
                                <button id='red-button' onClick={closeFirstPopup}>РАЗРЕШИТЬ ВСЕ</button>
                                <button id='gray-button' onClick={closeFirstPopup}>ОТКЛОНИТЬ ВСЕ</button>
                                <button id='gray-button' onClick={openSecondPopup}>ИЗМЕНИТЬ ПАРАМЕТРЫ</button>
                            </div>
                        </div>
                </div>
            </Popup>

            <Popup isOpen={isSecondPopupOpen} onClose={closeSecondPopup}>
                <div className="change-everything">
                    <div className="container">
                        <div className="change-everything__container">
                            <div className="change-everything-title">
                                Центр настроек конфиденциальности
                            </div>
                            <div className="change-everything-description">
                                Выберите, какие файлы cookie включить
                            </div>
                            <Accordion />
                            <div className="cookies-first-buttons">
                                <button id='red-button' onClick={closeSecondPopup}>РАЗРЕШИТЬ ВСЕ</button>
                                <button id='gray-button' onClick={closeSecondPopup}>ОТКЛОНИТЬ ВСЕ</button>
                                <button id='gray-button' onClick={closeSecondPopup}>СОХРАНИТЬ ИЗМЕНЕНИЯ</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default Cookies;
