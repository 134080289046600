import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Popup from "../Popup/Popup"; // Импортируем ваш попап
import './PhotoUpload.css';
import PhotoUploadedSuccessfully from "../Modals/PhotoUploadedSuccessfully/PhotoUploadedSuccessfully";
import jwtInterceoptorImage from "../../shared/jwtInterceoptorImage";
import {API_URL_USER} from "../../config/axios.config";

const PhotoUpload = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false); // Состояние для попапа
    const inputRef = useRef(null); // Референс для <input>

    const onDrop = useCallback((acceptedFiles) => {
        const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxSize = 10 * 1024 * 1024; // 10 MB

        if (uploadedFiles.length + acceptedFiles.length > 1) {
            setErrorMessage('Вы можете загрузить только 1 фотографию.');
            return;
        }

        const newFiles = acceptedFiles.map(file => {
            if (!validFormats.includes(file.type)) {
                setErrorMessage('Неверный формат файла. Используйте JPEG, JPG или PNG.');
                return null;
            }
            if (file.size > maxSize) {
                setErrorMessage('Файл слишком большой. Максимальный размер 10 MB.');
                return null;
            }
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        }).filter(file => file !== null);

        setErrorMessage('');

        setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }, [uploadedFiles]);

    const removeFile = (file) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((f) => f !== file));
        URL.revokeObjectURL(file.preview);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (uploadedFiles.length === 0) {
            setErrorMessage('Вы не выбрали фотографию.');
        } else {
            const z = new FormData();
            z.append('file', uploadedFiles[0]);
            jwtInterceoptorImage.post(`${API_URL_USER}/check/upload`, z).then(() => {
                setIsPopupOpen(true);
                setUploadedFiles([])
            });
        }

    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/jpg': [],
        },
        maxFiles: 2,
    });

    return (
        <div className="upload-container">
            <div {...getRootProps({ className: "dropzone" })}>
                <img src={require("../../img/free-icon-font-upload-3917358 1.png")} className="upload-image" alt=""/>
                <input {...getInputProps()} ref={inputRef} /> {/* Привязали реф */}
                <div className="upload-text">
                    {isDragActive ? (
                        <p>Перетащите фото сюда...</p>
                    ) : (
                        <p>Перетащите сюда фото чека <br/> или выберите файл
                            <br/>
                            <br/>
                            JPEG, JPG, PNG до 10 MB
                        </p>
                    )}
                </div>
            </div>
            <div className="uploaded-files">
                {uploadedFiles.map((file) => (
                    <div key={file.name} className="file-item">
                        <img src={file.preview} alt={file.name} className="preview-image" />
                        <button className="remove-button" onClick={() => removeFile(file)}>✖</button>
                    </div>
                ))}
            </div>
            {/* Кнопка "Выбрать фото" вызывает input кликом */}
            <button
                id="gray-button"
                className="choose-button"
                type="button"
                onClick={() => inputRef.current.click()} // Нажимаем на input
            >
                ВЫБРАТЬ ФОТО
            </button>
            <button id="red-button" className="submit-button" type="button" onClick={handleSubmit}>ОТПРАВИТЬ</button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {/* Попап для успешной отправки */}
            <PhotoUploadedSuccessfully
                isOpen={isPopupOpen}
                onClose={closePopup}
                onAgree={() => alert("Вы согласились!")} // Пример действия при согласии
            />
        </div>
    );
};

export default PhotoUpload;
