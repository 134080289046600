import CheckStatus from "../../../CheckStatus/CheckStatus";
import React from "react";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";

export const UploadCheck = () => {
    return (
        <div className={`tab-content active`}>
            <div  className="upload">
                <div className="upload__container">
                    <div className="upload__title" id="tab-title">Загрузите фото чека</div>
                    {/*<div className="upload__description" id='tab-description'>
                        Можно загрузить две фотографии, если на одной чек не помещается
                    </div>*/}
                    <PhotoUpload/>
                </div>
            </div>
        </div>
    )
}