import React from 'react';
import "./Footer.css"


function Footer(props) {
    return (
        <footer>
            <div className="footer" id='contacts'>
                <div className="container">
                    <div className="footer-container">
                    <div className="footer__info">
                        <div className="footer__logo">
                            <img src={require("../../../img/Слой_x0020_1.png")} alt=""/>
                        </div>
                        <div className="footer__contacts">
                            <div className="footer__contacts__title">
                                <p>КОНТАКТЫ:</p>
                            </div>
                            <div className="footer__contacts__description">
                                <p><a href="tel: +375 44 557 27 30">
                                    +375 44 557 27 30
                                </a></p>
                                <p>ПН-ПТ: 10:00 до 17:00 </p>
                                <p>В СБ: 10:00 до 13:00
                                </p>
                            </div>

                        </div>
                        <div className="footer__links">
                            <a href="/files/terms-of-use.pdf" download="terms-of-use.pdf">Условия использования</a>
                            <a href="/files/privacy-statement.pdf" download="privacy-statement.pdf">Положение о конфиденциальности</a>
                            <a href="/files/cookie-policy.pdf" download="cookie-policy.pdf">Политика "cookie"</a>
                        </div>
                    </div>
                    <div className="footer__about">

                        <p className="f-title">
                            Рекламная игра «Выигрывай со вкусом!».
                        </p>
                        <p className="f-description">
                            Сроки проведения рекламной игры: с 30 сентября 2024 г. по 30 января 2025 г. (включая период
                            розыгрышей и выдачи призов).
                            Полные правила рекламной игры на сайте www.lukoil-promo.by.
                            Организатор: ЧРУП «Промофреш», УНП 691757897
                            Свидетельство о государственной регистрации № 4470, выданное МАРТ РБ от 23.09.2024 г..
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;