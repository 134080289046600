import React, { useState } from 'react';
import Popup from "../../../Popup/Popup";
import './Agreements.css';

function Agreements(props) {
    const [isChecked, setIsChecked] = useState(false); // Состояние чекбокса

    const handleAgreeClick = () => {
        if (isChecked) {
            props.onAgree();
        }
    };

    // Обработчик изменения чекбокса
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Popup isOpen={props.isOpen} onClose={props.onClose}>
            <div className="unique-agreement-popup">
                <h2 className="unique-agreement-title">Согласие на обработку персональных данных</h2>
                <div className="unique-agreement-text">
                    <p align="center"></p>
                    <p>Для участия в рекламной игре &laquo;Выигрывай со вкусом!&raquo;, зарегистрированной Министерством антимонопольного регулирования и торговли Республики Беларусь 23.09.2024 под номером 4470 (далее &ndash; Рекламная игра), Вам необходимо предоставить согласие на обработку персональных данных путем проставления соответствующей отметки внизу данной формы. Перед этим Вам необходимо ознакомиться со следующей информацией.</p>
                    <p>1. Что такое &laquo;персональные данные&raquo; и &laquo;обработка персональных данных&raquo;?</p>
                    <p><b>Персональные данные</b>&nbsp;&ndash; любые сведения, по которым можно идентифицировать физическое лицо (установить его личность).</p>
                    <p><b>Обработка персональных данных</b>&nbsp;&ndash; любое действие или совокупность действий, совершаемые с персональными данными, включая сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, распространение, предоставление, удаление персональных данных.</p>
                    <p>2. Какие персональные данные необходимы для участия в Рекламной игре?</p>
                    <p>Для участия в Рекламной игре Вам необходимо зарегистрироваться на интернет-сайте lukoil-promo.by (далее &ndash; Сайт) путем внесения в соответствующие поля формы регистрации следующих персональных данных:</p>
                    <ul>
                        <li>фамилия, имя, отчество (при наличии);</li>
                        <li>дата рождения;</li>
                        <li>абонентский номер мобильного телефона белорусского оператора сотовой связи в международном формате;</li>
                        <li>адрес электронной почты;</li>
                        <li>игровые коды.</li>
                    </ul>
                    <p>3. Какая цель обработки персональных данных на основании Вашего согласия?</p>
                    <p>Обеспечение Вашего участия в Рекламной игре в соответствии с правилами Рекламной игры.</p>
                    <p>4. Кто будет обрабатывать персональные данные на основании Вашего согласия?</p>
                    <p>Владельцем Сайта, на котором Вы регистрируетесь, и, следовательно, юридическим лицом, организующим и осуществляющим обработку Ваших персональных данных совместно с другими юридическими лицами является&nbsp;<b>Частное рекламное унитарное предприятие &laquo;Промофреш&raquo;</b>, УНП 691757897, местонахождение: г. Минск, ул. Интернациональная, 25а, оф. 216 (далее &laquo;Оператор&raquo;). Оператор осуществляет обработку Ваших персональных данных с привлечением следующих уполномоченных лиц:</p>
                    <ul>
                        <li>ООО &laquo;Надежные программы&raquo; местонахождение: ул. Аранская, 8, блок 1, 4 этаж, 220006, Минск; УНП 100160363), оказывающее Оператору на основании договора услуги по предоставление в пользование информационно-технических ресурсов, а также услуги технической и криптографической защите информации, необходимые для работы Сайта.</li>
                    </ul>
                    <p>5. Как долго будут храниться персональные данные на основании Вашего согласия?</p>
                    <p>В случае предоставления Вами согласия на обработку указанных в пункте 2 персональных данных они будут обрабатываться Оператором с момента их регистрации на Сайте до окончания проведения Рекламной игры (по 30.01.2025).</p>
                    <p>Обращаем внимание, что Организатор в силу требований Положения о проведении рекламных игр на территории Республики Беларусь, утвержденного Указом Президента Республики Беларусь от 30.01.2003 № 51, после окончания Рекламной игры обязан хранить все материалы по Рекламной игре в течение 3 (трех) лет со дня ее окончания.</p>
                    <p>6. Какие действия будут совершаться с персональными данными?</p>
                    <p>Оператор осуществляет с персональными данными следующие действия: сбор, систематизация, хранение, копирование, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление), блокирование, уничтожение (удаление) как с использованием средств автоматизации, так и без использования таковых.</p>
                    <p>7. Какие права Вы имеете как субъект персональных данных?</p>
                    <p>ПРАВО</p>
                    <p>1. Право на получение информации об обработке персональных данных.</p>
                    <p>СОДЕРЖАНИЕ</p>
                    <p>Субъект персональных данных вправе получить информацию о том, какие его персональные данные, для чего, на каком основании и каким образом Оператор собирает и использует, в том числе информацию об Операторе и ее уполномоченных лицах, о сроке согласия на обработку персональных данных.</p>
                    <p>ПОРЯДОК РЕАЛИЗАЦИИ</p>
                    <p>В течение 5 (пяти) рабочих дней после получения заявления субъекта персональных данных Оператор предоставляет субъекту персональных данных запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</p>
                    <p>ПРАВО</p>
                    <p>2. Право на получение информации о предоставлении персональных данных третьим лицам.</p>
                    <p>СОДЕРЖАНИЕ</p>
                    <p>Субъект персональных данных вправе получать информацию о том, какие третьи лица и на каком основании имеют доступ к его персональным данным. Один раз в календарный год такая информация предоставляется бесплатно.</p>
                    <p>ПОРЯДОК РЕАЛИЗАЦИИ</p>
                    <p>В течение 15 (пятнадцати) календарных дней после получения заявления субъекта персональных данных Оператор предоставляет субъекту персональных данных запрашиваемую информацию или уведомление о причинах отказа в ее предоставлении в письменном виде.</p>
                    <p>ПРАВО</p>
                    <p>3. Право на внесение изменений в персональные данные.</p>
                    <p>СОДЕРЖАНИЕ</p>
                    <p>Оператор не проверяет достоверность персональных данных, предоставляемых субъектом персональных данных. Тем не менее, в любой момент субъект персональных данных вправе требовать от Оператора внести изменения в его персональные данные в случаях, если они являются неполными, неточными или утратили актуальность. К заявлению субъекта персональных данных следует приложить документы, подтверждающие необходимость внесения изменений в персональные данные.</p>
                    <p>ПОРЯДОК РЕАЛИЗАЦИИ</p>
                    <p>В течение 15 (пятнадцати) календарных дней после получения заявления субъекта персональных данных Оператор внесет соответствующие изменения в персональные данные субъекта персональных данных.</p>
                    <p>ПРАВО</p>
                    <p>4. Право на прекращение обработки персональных данных или их удаление.</p>
                    <p>СОДЕРЖАНИЕ</p>
                    <p>Субъект персональных данных вправе требовать от Оператора бесплатного прекращения обработки его персональных данных, включая их удаления при отсутствии (утрате) оснований для обработки таких данных.</p>
                    <p>ПОРЯДОК РЕАЛИЗАЦИИ</p>
                    <p>В течение 15 (пятнадцати) календарных дней после получения заявления субъекта персональных данных Оператор прекратит обработку персональных данных субъекта персональных данных за исключением случаев, когда Оператор вправе продолжить обработку персональных данных при наличии оснований, установленных законодательством.</p>
                    <p>ПРАВО</p>
                    <p>5. Право на отзыв согласия на обработку персональных данных</p>
                    <p>СОДЕРЖАНИЕ</p>
                    <p>Субъект персональных данных вправе в любой момент отозвать свое согласие на обработку персональных данных, если на момент обращения к Оператору у него нет оснований для обработки персональных данных.</p>
                    <p>ПОРЯДОК РЕАЛИЗАЦИИ</p>
                    <p>В течение 5 (пяти) рабочих дней после получения отзыва согласия Оператор в письменном виде уведомляет субъекта персональных данных о том, что его персональные данные уничтожены.</p>
                    <p>Согласен на обработку персональных данных</p>
                </div>
                <div className="unique-checkbox-container">
                    <input
                        type="checkbox"
                        id="unique-agree-checkbox"
                        className="unique-checkbox"
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="unique-agree-checkbox"><p>
                        Я подтверждаю, что ознакомился(лась) с информацией о порядке обработки персональных данных, с правами и механизмом реализации прав субъекта персональных данных, а также с последствиями дачи согласия на обработку персональных данных и отказа в даче такого согласия, и я даю свое согласие Оператору, Частному рекламному унитарному предприятию «Промофреш», на обработку моих персональных данных на условиях, указанных в настоящей форме выше.
                        <br/>Это поле обязательно для заполнения.
                    </p></label>
                </div>
                <div className="unique-checkbox-button">
                    <button
                        className={`unique-agree-button ${isChecked ? 'active' : ''}`}
                        onClick={handleAgreeClick}
                        disabled={!isChecked}
                    >
                        ДАЮ СОГЛАСИЕ
                    </button>
                </div>

            </div>
        </Popup>
    );
}

export default Agreements;
