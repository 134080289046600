import React from 'react';
import {useNavigate} from "react-router-dom";


function NewForgotPassSuccess({props}) {

    return (
        <div>
            <div className="ForgotPass">
                <div className="ForgotPass__container">
                    <div className="ForgotPass-title">
                        <p>
                            Ваш пароль успешно изменен
                        </p>
                    </div>
                    <div className="ForgotPass-button" id='red-button'>
                        <span onClick={() => window.location.reload()}>Войти в кабинет</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewForgotPassSuccess;