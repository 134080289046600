import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AccordionFaq.css';

const UniqueAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="accordion" id='faq'>
            <div className="container">

            <div className="accordion__title">Вопросы</div>
            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(0)}>
                    <span>Период, когда можно стать участником Рекламной игры?</span>
                    <span className={`arrow ${activeIndex === 0 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 0 ? 'open' : ''}`}>
                    <p>С 30 сентября 2024 года по 24 ноября 2024 года включительно.</p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(1)}>
                    <span>Кто может принять участие в рекламной игре?</span>
                    <span className={`arrow ${activeIndex === 1 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 1 ? 'open' : ''}`}>
                    <p>
                        К участию в Рекламной игре приглашаются все граждане Республики Беларусь, постоянно проживающие в Республике Беларусь, а также иностранные граждане и лица без гражданства, имеющие вид на жительство на территории Республики Беларусь и постоянно проживающие в Республике Беларусь, с учетом ограничений, изложенных в настоящих <a href="/files/RULES-dirol.pdf" target="_blank" download='RULES-dirol.pdf'>Правилах</a>.
                    </p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(2)}>
                    <span>Как стать участником рекламной игры?</span>
                    <span className={`arrow ${activeIndex === 2 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 2 ? 'open' : ''}`}>
                    <p>
                        1. Для участия в рекламной игре необходимо приобрести любой Игровой продукт из Таблицы №1 в период с 30 сентября 2024 года по 24 ноября 2024 года включительно на АЗС/МАЗС ИООО «ЛУКОЙЛ Белоруссия», расположенных на территории Республики Беларусь:
                    </p>
                    <p>Таблица №1. Игровые продукты:</p>
                    <div className="responsive-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Код товара</th>
                                <th>Наименование продукта</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>7622201731854</td>
                                <td>Жеват.резинка б/сах. ароматизир. DIROL COLORS (КОЛОРС) XXL Ассорти мятных вкусов 19г</td>
                            </tr>
                            <tr>
                                <td>7622201685881</td>
                                <td>Жеват.резинка б/сах. ароматизир. DIROL FRUIT MIX XXL Ассорти фруктовых вкусов 19г</td>
                            </tr>
                            <tr>
                                <td>7622201677367</td>
                                <td>Жеват.резинка б/сах. DIROL XXL АРБУЗНАЯ СВЕЖЕСТЬ со вкусом арбуза 19г</td>
                            </tr>
                            <tr>
                                <td>46103499</td>
                                <td>Жеват.резинка б/сах. Dirol с мятным вкусом Мята 13,6г</td>
                            </tr>
                            <tr>
                                <td>46103550</td>
                                <td>Жеват.резинка б/сах. Dirol Арбузно-дынный коктейль 13,6г</td>
                            </tr>
                            <tr>
                                <td>46103451</td>
                                <td>Жеват.резинка б/сах. Дирол с мятным вкусом Морозная мята 13,6г</td>
                            </tr>
                            <tr>
                                <td>57023861</td>
                                <td>Жеват.резинка б/сах. Dirol X-Fresh свежесть черники и цитруса 16г</td>
                            </tr>
                            <tr>
                                <td>46164292</td>
                                <td>Жеват.резинка б/сах. DIROL X-FRESH с арбузным вкусом Арбузный лед 16г</td>
                            </tr>
                            <tr>
                                <td>57027906</td>
                                <td>Жеват.резинка б/сах. DIROL со вкусом Кокоса и Клубники 13,6г</td>
                            </tr>
                            <tr>
                                <td>46090812</td>
                                <td>Жеват.резинка б/сах. DIROL со вкусом перечной мяты Ледяная мята 13,6г</td>
                            </tr>
                            <tr>
                                <td>7622201429959</td>
                                <td>Жеват.резинка б/сах. DIROL White со вкусом клубники 13,6г</td>
                            </tr>
                            <tr>
                                <td>46149305</td>
                                <td>Жеват.резинка б/сах. Dirol Клубника-Черешня со вкусом клубники и черешни 13,6г</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                        2. Получить Кассовый чек, подтверждающий приобретение Игрового продукта и содержащий следующие сведения:

                    <ul>
                        <li>сведения о номере документа;</li>
                        <li>наименование продавца (торгового объекта, где были приобретены Игровые продукты);</li>
                        <li>учетный номер плательщика (УНП);</li>
                        <li>дату и время продажи Игровых продуктов;</li>
                        <li>наименование Игровых продуктов;</li>
                        <li>количество и цену Игровых продуктов;</li>
                        <li>итоговую сумму покупки.</li>
                    </ul>
                    <p>
                        3. Зарегистрироваться на Интернет-сайте www.lukoil-promo.by, (далее – Интернет-сайт), заполнив регистрационную форму на Интернет-сайте, достоверно указав следующую информацию:
                    </p>
                    <ul>
                        <li>фамилия (обязательно), имя (обязательно), отчество (при наличии);</li>
                        <li>дата рождения (обязательно);</li>
                        <li>абонентский номер мобильного телефона белорусского оператора сотовой связи Участника в международном формате (обязательно);</li>
                        <li>адрес электронной почты (обязательно).</li>
                    </ul>
                    <p>
                        4. Загрузить на Интернет-сайт через личный кабинет фотографию регистрируемого Кассового чека. Изображение Кассового чека должно быть чётким. Данные из 1 (одного) Кассового чека, которые регистрирует Участник, принимаются к участию в Рекламной игре один раз. Сохранить чек(-и) до даты окончания Рекламной игры включительно.
                    </p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(3)}>
                    <span>Какие товары необходимо купить для участия в рекламной игре?</span>
                    <span className={`arrow ${activeIndex === 3 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 3 ? 'open' : ''}`}>
                    <p>Для участия в рекламной игре необходимо приобрести любой Игровой продукт. С полным перечнем товаров, участвующих в рекламной игре можно ознакомиться  <a href="/files/RULES-dirol.pdf" target="_blank" download='RULES-dirol.pdf'>здесь</a>.</p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(4)}>
                    <span>Где можно получить информацию о правилах и условиях участия в Рекламной игре?</span>
                    <span className={`arrow ${activeIndex === 4 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 4 ? 'open' : ''}`}>
                    <p>Информацию о <a href="/files/RULES-dirol.pdf" target="_blank" download='RULES-dirol.pdf'>правилах</a> и условиях участия в Рекламной игре можно получить на Интернет-сайте www.lukoil-promo.by, а также по телефону горячей линии: <a
                        href="tel:+375 44 557 27 30">+375 44 557 27 30</a> с 10:00 до 17:00 без обеда в будние дни. В субботу с 10:00 до 13:00.</p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(5)}>
                    <span>Когда и где проходят розыгрыши?</span>
                    <span className={`arrow ${activeIndex === 5 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 5 ? 'open' : ''}`}>
                    <p>Розыгрыши Призов проходят по адресу: г. Минск, ул. Интернациональная, 25а, оф. 216.</p>
                    <p>С графиком проведения розыгрышей можно ознакомиться  <a href="/files/RULES-dirol.pdf" target="_blank" download='RULES-dirol.pdf'>здесь</a>.</p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(6)}>
                    <span>Как узнать о результатах розыгрыша?</span>
                    <span className={`arrow ${activeIndex === 6 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 6 ? 'open' : ''}`}>
                    <p>Победители Рекламной игры извещаются о выигрыше звонком на номер телефона, указанный при регистрации на Интернет-сайте, в течение 3 (трёх) дней с даты розыгрыша.</p>
                    <p>Организатор также оставляет за собой право уведомлять Победителей любым удобным ему способом, помимо уже указанных: SMS сообщением на номер телефона и/или почтовым уведомлением на электронный адрес Участника, указанный в личном кабинете при регистрации.</p>
                    <p>Также список победителей можно посмотреть в разделе – Победители.</p>
                </div>
            </div>

            <div className="accordion-item">
                <div className="accordion-header" onClick={() => toggleAccordion(7)}>
                    <span>Где и когда можно получить приз?</span>
                    <span className={`arrow ${activeIndex === 7 ? 'down' : ''}`}></span>
                </div>
                <div className={`accordion-content ${activeIndex === 7 ? 'open' : ''}`}>
                    <p>Для получения Приза №1 (Подарочный сертификат на приобретение топлива, реализуемого торговой сетью автозаправочных станций ИООО «ЛУКОЙЛ Белоруссия» номинальной стоимостью 100,00 (сто) белорусских рублей) Победителю нужно в срок не позднее, чем за 7 (семь) дней до окончания периода выдачи приза предоставить Организатору по электронной почте <a href="mailto:game@fresh-ideas.by">game@fresh-ideas.by</a> данные, необходимые для отправки Приза по почте, а именно: фамилию, имя, отчество (при наличии) Победителя, контактный номер телефона, адрес пребывания/проживания в Республике Беларусь: индекс, область, район, населенный пункт, улица (при наличии), дом (при наличии), корпус (при наличии), номер квартиры (при наличии).</p>
                    <p>Призы будут отправлены посредством заказного почтового отправления с уведомлением о доставке в течение 7 (семи) дней со дня получения данных от Победителя на электронную почту Организатора  (<a href="mailto:game@fresh-ideas.by">game@fresh-ideas.by</a>), необходимых для отправления Приза почтой. Организатор считается выполнившим свои обязательства по выдаче приза в день соответствующего отправления. Денежная компенсация стоимости призов не производится.</p>
                    <p>Призы №2 (Денежный приз в размере 3 500 (Три тысячи пятьсот) белорусских рублей и дополнительные денежные средства в размере возмещения Подоходного налога) и Приз №3 (Денежный приз в размере 7 000 (Семь тысяч) белорусских рублей и дополнительные денежные средства в размере возмещения Подоходного налога) можно получить по адресу: Республика Беларусь, 220030, г. Минск, ул. Интернациональная, д.25а, оф. 216, в рабочие дни с 10:00 до 17:00, а также в субботу с 10:00 до 13:00.</p>
                    <p>Дату и время прибытия необходимо предварительно согласовать с Организатором по телефону +375 (44) 557-27-30 (по будним дням с 10:00 до 17:00, а также в субботу с 10:00 до 13:00). Звонок платный, в соответствии с тарифами операторов, услугами которых пользуется Участник.</p>
                    <p>Для получения Призов №2 и №3 Победитель должен предоставить Организатору номер карт-счета, открытого в белорусских рублях в банках Республики Беларусь, а также свои паспортные данные (фамилия, имя, отчество (при наличии), серия и номер паспорта/вида на жительство/ид.карты, дата выдачи и кем выдан документ, удостоверяющий личность, адрес регистрации) лично или по электронной почте <a href="mailto:game@fresh-ideas.by">game@fresh-ideas.by</a>.</p>
                    <p>Призы №2 и №3 передаются Победителям в безналичном порядке на указанные Победителями банковские счета Победителей в банках Республики Беларусь в день явки Победителя за получением Приза в срок, указанный в Таблице №4 п.12.1 настоящих <a href="/files/RULES-dirol.pdf" target="_blank" download='RULES-dirol.pdf'>Правил</a>.</p>
                </div>
            </div>
        </div>
        </div>

    );
};

export default UniqueAccordion;
