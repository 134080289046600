export const API_URL = "https://api.lukoil-promo.by/api/v1"
export const API = "https://api.lukoil-promo.by"
export const API_URL_USER = "https://api.lukoil-promo.by/api/v1/user"
export const API_URL_ADMIN = "https://api.lukoil-promo.by/api/v1/admin"

/*export const API_URL = "http://localhost:8080/api/v1"
export const API = "http://localhost:8080"
export const API_URL_USER = "http://localhost:8080/api/v1/user"
export const API_URL_ADMIN = "http://localhost:8080/api/v1/admin"*/

export const CONFIGFETCH = {
    headers: {
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem('client'))}`,
        "Accept" : "*/*",
        "Access-Control-Allow-Origin" : '*',
        "Content-Type" : "application/json"
    },
    method: "GET",
}
/*

с 00:00:00 30.09.2024
по 23:59:59 06.10.2024
то 11.10.2024 в 12:30

с 00:00:00 07.10.2024
по 23:59:59 13.10.2024
то 18.10.2024 в 12:30

с 00:00:00 14.10.2024
по 23:59:59 20.10.2024
то 25.10.2024 в 12:30

с 00:00:00 21.10.2024
по 23:59:59 27.10.2024
то 01.11.2024 в 12:30

с 00:00:00 30.09.2024
по 23:59:59 27.10.2024
то 01.11.2024 в 12:30

с 00:00:00 28.10.2024
по 23:59:59 03.11.2024
то 01.11.2024 в 12:30

с 00:00:00 04.11.2024
по 23:59:59 10.11.2024
то 01.11.2024 в 12:30


с 00:00:00 11.11.2024
по 23:59:59 17.11.2024
то 01.11.2024 в 12:30

с 00:00:00 18.11.2024
по 23:59:59 24.11.2024
то 01.11.2024 в 12:30

с 00:00:00 28.10.2024
по 23:59:59 24.11.2024
то 01.11.2024 в 12:30

с 00:00:00 30.09.2024
по 23:59:59 24.11.2024
то 01.11.2024 в 12:30
*/
