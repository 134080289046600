import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

export const CustomNavLink = ({ to, title }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <NavLink to={to} className={`tab-link ${match && 'tab-link-active'}`} >
            {title}
        </NavLink>
    )
}