import axios from "axios";

const jwtInterceoptorImage = axios.create({});

jwtInterceoptorImage.interceptors.request.use((config) => {
    let tokensData = JSON.parse(localStorage.getItem("client"));
    config.headers["Authorization"] = `Bearer ${tokensData}`;
    config.headers["Content-Type"] = `multipart/form-data`;
    return config;
});

jwtInterceoptorImage.interceptors.response.use(
    (response) => {
        console.log(response)
        return response;
    },
    async (error) => {
        console.log(error);
        if (error.response.status === 403) {
            localStorage.removeItem("client")
            window.location.reload();
            return axios(error.config);
        } else {
            return Promise.reject(error);
        }
    }
);

export default jwtInterceoptorImage;