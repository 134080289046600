import React, {useEffect, useState} from "react";
import jwtInterceoptor from "../../../shared/jwtInterceptor";
import {API_URL_USER} from "../../../config/axios.config";

export function formatDate(dateString) {
    try {
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    } catch {
        return "";
    }
}

export const Profile = () => {
    const [data, setData] = useState({
        "name": null,
        "lastname": null,
        "surname": null,
        "birthday": null,
        "phoneNumber": null,
        "email": null
    });

    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return phoneNumber;
        const cleaned = phoneNumber.replace(/\D/g, '');
        if (cleaned.startsWith('375') && cleaned.length === 12) {
            return `+${cleaned.slice(0, 3)} (${cleaned.slice(3, 5)}) ${cleaned.slice(5, 8)}-${cleaned.slice(8, 10)}-${cleaned.slice(10)}`;
        }
        return phoneNumber;
    }

    useEffect(() => {
        jwtInterceoptor.get(`${API_URL_USER}/profile`).then(x => {
            setData(x.data);
        })
    }, [])

    return (
        <div className={`tab-content active`} id="content-1">
            <div className="tap-container">
                <div className="tap-profile">
                    <div id="tab-title" className="tap-profile__title">Мои данные</div>
                    <div id="tab-description" className="tap-profile__description">
                        Если вы хотите изменить свои данные,
                        обратитесь по номеру Горячей линии <br/><a href="tel:+375445572730">+375 44
                        557-27-30</a>
                    </div>
                    <div className="tap-profile__data">
                        <div className="profile__table">
                            <dl className="profile__row">
                                <dt className="profile__term term">Фамилия</dt>
                                <dd className="profile__details details">{data?.surname}</dd>
                            </dl>
                            <dl className="profile__row">
                                <dt className="profile__term term">Имя</dt>
                                <dd className="profile__details details">{data?.name}</dd>
                            </dl>
                            <dl className="profile__row">
                                <dt className="profile__term term">Отчество</dt>
                                <dd className="profile__details details">{data?.lastname}</dd>
                            </dl>
                            <dl className="profile__row">
                                <dt className="profile__term term">Дата рождения</dt>
                                <dd className="profile__details details">{formatDate(data?.birthday)}</dd>
                            </dl>
                            <dl className="profile__row">
                                <dt className="profile__term term">Телефон</dt>
                                <dd className="profile__details details">{formatPhoneNumber(data?.phoneNumber)}</dd>
                            </dl>
                            <dl className="profile__row">
                                <dt className="profile__term term">Email</dt>
                                <dd className="profile__details details">{data?.email}</dd>
                            </dl>
                        </div>
                    </div>
                    {/*<form action="" className="tap-container-form">*/}
                    {/*    <div className="checkbox-container">*/}
                    {/*        <input type="checkbox" name="delete-acc"*/}
                    {/*               className="delete-acc a11y-hidden"*/}
                    {/*               id="delete-acc" tabIndex="3"/>*/}
                    {/*        <label className="label-delete-acc" htmlFor="delete-acc">*/}
                    {/*            <span>Я хочу удалить свой аккаунт*/}
                    {/*                и все предоставленные данные</span>*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*    <a href="#">*/}
                    {/*        УДАЛИТЬ АККАУНТ*/}
                    {/*    </a>*/}
                    {/*</form>*/}
                </div>
            </div>
        </div>
    )
}