import axios from "axios";
import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {API_URL} from "../config/axios.config";

const AuthContext = createContext(undefined);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("client")) {
      try {
        let tokens = JSON.parse(localStorage.getItem("client"));
        return jwt_decode(tokens);
      } catch (e) {
        localStorage.removeItem("client");
        return null;
      }
    }
    return null;
  });

  const navigate = useNavigate();

  const login = async (payload, error) => {
    const apiResponse = await axios.post(API_URL + "/login", payload);
    if (apiResponse.data.status === "FOUND") {
        error("Пользователь с такими данными не найден")
    }
    else {
      localStorage.setItem("client", JSON.stringify(apiResponse.data.data));
      setUser(jwt_decode(apiResponse.data.data));
      if (jwt_decode(apiResponse.data.data).role === "ROLE_USER") {
        if (jwt_decode(apiResponse.data.data).everify === "true") {
          navigate("/personal-account")
        } else {
          navigate("/")
        }
      }
      else {
        navigate("/")
      }
    }
  };
  const logout = async () => {
    localStorage.removeItem("client");
    setUser(null);
  };

  const register =  async (x, errors) => {
    const body = {
      "email": x.email,
      "password": x.password,
      "phoneNumber": x.phone,
      "profile": {
        "birthday": x.dob,
        "name": x.name,
        "surname": x.surname,
        "lastname": x.patronymic
      }
    };
    const headers = {
        headers: {
          'Content-Type': 'application/json',
        }
    }
    axios.post(API_URL + "/registration", body, headers).then(r => {
        if (r.data.status === "FOUND") {
          if (r.data.error === "Email used") {
            errors((prev) => ({...prev, email: "Email уже используется" }))
          }
          if(r.data.error === "Phone number used") {
            errors((prev) => ({...prev, phone: "Номер телефона уже используется" }))
          }
          if(r.data.error === "Email invalid") {
            errors((prev) => ({...prev, email: "Недействительный email" }))
          }
        }
        if (r.data.statusCode === 200) {
         /* localStorage.setItem("client", JSON.stringify(r.data.data));
          setUser(jwt_decode(r.data.data));*/
          navigate("/")
        }
    })
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
