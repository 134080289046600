import axios from "axios";

const jwtInterceoptor = axios.create({});


jwtInterceoptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("client"));
  config.headers["Authorization"] = `Bearer ${tokensData}`;
  return config;
});

jwtInterceoptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
        localStorage.removeItem("client")
        window.location.reload();
        return axios(error.config);
    } else {
        if (error.response.status === 403) {
            localStorage.removeItem("client");
            window.location.reload();
        } else return Promise.reject(error);
    }
  }
);

export default jwtInterceoptor;


