import React, { useState } from 'react';
import './PopUpCheckEmail.css'
import Popup from "../../../Popup/Popup";

function PopUpCheckEmail(props) {

    return (

        // <Popup isOpen={props.isOpen} onClose={props.onClose}>

        <div className="PopUpCheckEmail">
            <div className="CheckEmail__title"><p>
                Пожалуйста, проверьте
                ваш e-mail
            </p> </div>
            <div className="CheckEmail__description">
                <p>
                    Мы отправили письмо на ваш
                    электронный адрес для завершения
                    процесса регистрации.
                </p>
            </div>
        </div>
        // {/*</Popup>*/}
    );
}

export default PopUpCheckEmail