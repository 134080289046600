import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import AuthContext from "../../../shared/AuthContext";

function Header() {
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 982);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Проверяем размер при монтировании

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const closeMenu = () => {
        document.getElementById('menu-toggle').checked = false;
    };

    const handleFaqClick = () => {
        // Переход на главную страницу
        navigate('/');
        // Прокрутка к секции FAQ
        setTimeout(() => {
            const faqSection = document.getElementById('faq'); // ID секции FAQ
            if (faqSection) {
                faqSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Задержка для ожидания полной загрузки страницы
    };

    return (
        <div className="container__header">
            <div className="container">
                <header className="header">
                    <section className="top-nav">
                        <div className="header__logo">
                            <img
                                onClick={() => navigate("/")}
                                src={require("../../../img/Слой_x0020_1.png")}
                                alt="Логотип"
                                className="logo-lukoil"
                            />
                        </div>
                        <div className="header-menu-and-user">
                            <div className="header__menu">
                                <input id="menu-toggle" type="checkbox" />
                                <label className='menu-button-container' htmlFor="menu-toggle">
                                    <div className='menu-button'></div>
                                </label>
                                <ul className={`menu ${isMobile ? 'mobile-menu' : ''}`}>
                                    <li>
                                        <a href="/files/RULES-dirol.pdf" target='_blank' download='RULES-dirol.pdf' onClick={closeMenu}>
                                            Правила
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/"
                                            onClick={(e) => {
                                                e.preventDefault(); // Предотвращаем переход по умолчанию
                                                handleFaqClick();
                                                closeMenu();
                                            }}
                                        >
                                            Вопросы
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/winners" onClick={closeMenu}>Победители</a>
                                    </li>
                                    <li>
                                        <a href="#contacts" onClick={closeMenu}>Контакты</a>
                                    </li>
                                    <li>
                                        <a id="header-number" href="tel:+375445572730" onClick={closeMenu}>+375 (44) 557-27-30</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="header__user">
                                <div className="header__user__container">
                                    {user && user.everify === "true" ? (
                                        <Link to="/personal-account" onClick={closeMenu}>
                                            <img
                                                alt="Личный кабинет"
                                                src={require("../../../img/Group 8123137.png")}
                                                className="user-logo"
                                            />
                                        </Link>
                                    ) : (
                                        <a href="#registrationUser" onClick={closeMenu}>
                                            <img
                                                alt="Личный кабинет вход"
                                                src={require("../../../img/Frame 31.png")}
                                                className="user-logo"
                                            />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </header>
            </div>
        </div>
    );
}

export default Header;
