import React, {useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './UniqueRegistrationForm.css';
import AuthContext from "../../../../shared/AuthContext";
import axios from "axios";
import {API_URL} from "../../../../config/axios.config";

function UniqueRegistrationForm({ setShowPopup, setIsCheckEmailOpen }) {
    const {register} = useContext(AuthContext);
    const [formData, setFormData] = useState({
        surname: '',
        name: '',
        patronymic: '',
        dob: '',
        phone: '375',
        email: '',
        password: '',
        confirmPassword: '',
        rulesAgreed: false,
        noRelation: false,
    });

    const [errors, setErrors] = useState({});
    const [passwordValidation, setPasswordValidation] = useState({
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        if (value.startsWith('375')) {
            if (value.length <= 12) {
                setFormData((prevData) => ({
                    ...prevData,
                    phone: value,
                }));
            }
        } else if (value === '' || value === '3' || value === '37' || value === '375') {
            setFormData((prevData) => ({
                ...prevData,
                phone: '375',
            }));
        }
    };

    const validatePassword = (password) => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[-_!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasMinLength = password.length >= 8;

        setPasswordValidation({
            hasUppercase,
            hasNumber,
            hasSpecialChar,
            hasMinLength,
        });

        return hasUppercase && hasNumber && hasSpecialChar && hasMinLength;
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({...prevData, password: value}));
        validatePassword(value);
    };

    const validate = () => {
        let errors = {};
        if (!formData.surname.trim()) errors.surname = 'Фамилия обязательна';
        if (!formData.name.trim()) errors.name = 'Имя обязательно';
        if (!formData.dob) errors.dob = 'Дата рождения обязательна';
        if (!formData.phone.match(/^375\d{9}$/)) errors.phone = 'Неверный формат телефона';
        if (!formData.email.includes('@')) errors.email = 'Неверный формат email';
        if (!formData.password) errors.password = 'Пароль обязателен';
        if (!validatePassword(formData.password)) errors.password = 'Пароль не соответствует требованиям';
        if (formData.password !== formData.confirmPassword) errors.confirmPassword = 'Пароли не совпадают';
        if (!formData.rulesAgreed) errors.rulesAgreed = true;


        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            const body = {
                "email": formData.email,
                "password": formData.password,
                "phoneNumber": formData.phone,
                "profile": {
                    "birthday": formData.dob,
                    "name": formData.name,
                    "surname": formData.surname,
                    "lastname": formData.patronymic
                }
            };

     // --------------решение с датой ----------------







            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            axios.post(API_URL + "/registration", body, headers).then(r => {
                if (r.data.status === "FOUND") {
                    if (r.data.error === "Email used") {
                        setErrors((prev) => ({...prev, email: "Email уже используется" }))
                    }
                    if(r.data.error === "Phone number used") {
                        setErrors((prev) => ({...prev, phone: "Номер телефона уже используется" }))
                    }
                    if(r.data.error === "Email invalid") {
                        setErrors((prev) => ({...prev, email: "Недействительный email" }))
                    }
                    setIsSubmitting(false);
                }
                if (r.data.statusCode === 200) {
                    setShowPopup(false);
                    setIsCheckEmailOpen(true);
                }
            })
        } else {
            setIsSubmitting(false);
        }
    };

    return (
        <form className="unique-registration-form" onSubmit={handleSubmit}>
            <h2 className="unique-form-title">Форма регистрации</h2>

            <input
                type="text"
                name="surname"
                placeholder="Фамилия*"
                value={formData.surname}
                onChange={handleChange}
                className={errors.surname ? 'unique-input-error' : 'unique-input'}
            />
            {errors.surname && <span className="unique-error-message">{errors.surname}</span>}

            <input
                type="text"
                name="name"
                placeholder="Имя*"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'unique-input-error' : 'unique-input'}
            />
            {errors.name && <span className="unique-error-message">{errors.name}</span>}

            <input
                type="text"
                name="patronymic"
                placeholder="Отчество (при наличии)"
                value={formData.patronymic}
                onChange={handleChange}
                className="unique-input"
            />

            <input
                type="date"
                name="dob"
                placeholder="Дата рождения*"
                value={formData.dob}
                onChange={handleChange}
                className={errors.dob ? 'unique-input-error' : 'unique-input'}
                max={new Date().toISOString().split("T")[0]}
            />
            {errors.dob && <span className="unique-error-message">{errors.dob}</span>}

            <input
                type="text"
                name="phone"
                placeholder="Номер контактного телефона*"
                value={formData.phone}
                onChange={handlePhoneChange}
                className={errors.phone ? 'unique-input-error' : 'unique-input'}
            />
            {errors.phone && <span className="unique-error-message">{errors.phone}</span>}

            <input
                type="email"
                name="email"
                placeholder="E-mail*"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'unique-input-error' : 'unique-input'}
            />
            {errors.email && <span className="unique-error-message">{errors.email}</span>}

            <div className="unique-password-container">
                <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Пароль*"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    className={errors.password ? 'unique-input-error' : 'unique-input'}
                />
                <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
            </div>
            {errors.password && <span className="unique-error-message">{errors.password}</span>}

            <div className="password-requirements">
                <p className={passwordValidation.hasMinLength ? 'valid' : 'invalid'}>Не менее 8 символов</p>
                <p className={passwordValidation.hasUppercase ? 'valid' : 'invalid'}>Хотя бы одна заглавная буква</p>
                <p className={passwordValidation.hasNumber ? 'valid' : 'invalid'}>Хотя бы одна цифра</p>
                <p className={passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}>Хотя бы один специальный символ</p>
            </div>

            <input
                type="password"
                name="confirmPassword"
                placeholder="Подтвердите пароль*"
                value={formData.confirmPassword}
                onChange={handleChange}
                className={errors.confirmPassword ? 'unique-input-error' : 'unique-input'}
            />
            {errors.confirmPassword && <span className="unique-error-message">{errors.confirmPassword}</span>}

            <div className="unique-checkbox-container">
                <input
                    type="checkbox"
                    name="rulesAgreed"
                    id="rulesAgreed"
                    className={`unique-checkbox ${errors.rulesAgreed ? 'error-checkbox' : ''}`}
                    checked={formData.rulesAgreed}
                    onChange={handleChange}
                />
                <label htmlFor="rulesAgreed" className={`unique-checkbox-label ${errors.rulesAgreed ? 'error-text' : ''}`}>
                   <p> Я ознакомлен и согласен с <a href="/files/terms-of-use.pdf" target="_blank" download='terms-of-use.pdf'>Правилами Рекламной игры и Правилами пользования сайтом</a></p>
                </label>
            </div>



            <button type="submit" className="unique-submit-button" disabled={isSubmitting}>
                {isSubmitting ? 'Загрузка...' : 'Зарегистрироваться'}
            </button>
        </form>
    );
}

export default UniqueRegistrationForm;





