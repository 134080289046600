import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "./NewForgotPass.css"
import axios from "axios";
import {API} from "../../../../config/axios.config";

function NewForgotPass({ setIsAfterEmailOpen, code, thisPopup}) {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});
    const [passwordValidation, setPasswordValidation] = useState({
        hasUppercase: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasMinLength: false,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const validatePassword = (password) => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const hasMinLength = password.length >= 8;

        setPasswordValidation({
            hasUppercase,
            hasNumber,
            hasSpecialChar,
            hasMinLength,
        });

        return hasUppercase && hasNumber && hasSpecialChar && hasMinLength;
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (name === 'password') {
            validatePassword(value);
        }
    };

    const validate = () => {
        let errors = {};
        if (!formData.password) errors.password = 'Пароль обязателен';
        if (!validatePassword(formData.password)) errors.password = 'Пароль не соответствует требованиям';
        if (formData.password !== formData.confirmPassword) errors.confirmPassword = 'Пароли не совпадают';
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            let body = {
                "code" : code,
                "password" : formData.password
            }
            axios.post(`${API}/password/set`, body).then(r => {
                if (r.data.status === "FOUND") {
                    setErrors(r.data.error);
                    setIsSubmitting(false);
                }
                if (r.data.data === "ok") {
                    thisPopup(false);
                    setIsSubmitting(false);
                    setIsAfterEmailOpen(true);
                }
            })
        }
    };

    return (
        <div className='NewForgotPass__container'>
        <form className="ForgotPass__form" onSubmit={handleSubmit}>
<div className="NewForgotPas-title">
    <p>
        Введите ваш новый пароль
    </p>
</div>
            <div className="unique-password-container">
                <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Новый пароль*"
                    value={formData.password}
                    onChange={handlePasswordChange}
                    className={errors.password ? 'unique-input-error' : 'unique-input'}
                />
                <button
                    type="button"
                    className="toggle-password"
                    onClick={() => setShowPassword(!showPassword)}
                >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
            </div>
            {errors.password && <span className="unique-error-message">{errors.password}</span>}

            <div className="password-requirements">
                <p className={passwordValidation.hasMinLength ? 'valid' : 'invalid'}>Не менее 8 символов</p>
                <p className={passwordValidation.hasUppercase ? 'valid' : 'invalid'}>Хотя бы одна заглавная буква</p>
                <p className={passwordValidation.hasNumber ? 'valid' : 'invalid'}>Хотя бы одна цифра</p>
                <p className={passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}>Хотя бы один специальный символ</p>
            </div>

            <input
                type="password"
                name="confirmPassword"
                placeholder="Подтвердите новый пароль*"
                value={formData.confirmPassword}
                onChange={handlePasswordChange}
                className={errors.confirmPassword ? 'unique-input-error' : 'unique-input'}
            />
            {errors.confirmPassword && <span className="unique-error-message">{errors.confirmPassword}</span>}

            <button type="submit" className="unique-submit-button" disabled={isSubmitting}>
                {isSubmitting ? 'Загрузка...' : 'Сбросить пароль'}
            </button>
        </form>
        </div>
    );
}

export default NewForgotPass;
