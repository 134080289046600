import React, {useState} from 'react';
import './ForgotPass.css'
import axios from "axios";
import {API} from "../../../../config/axios.config";

function ForgotPass({setIsAfterEmailOpen}) {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [send, setSend] = useState(false);

    const handleClick = () => {
        axios.post(`${API}/password/recover/${email}`).then(x => {
            if (x.data.status === "FOUND") {
                setError("Пользователь с такой почтой не найден.")
            }
            if (x.data.data === "send") {
                setSend(true);
            }
        })
    };

    return (
        <div>
            <div className="ForgotPass">
                <div className="ForgotPass__container">
                    <div className="ForgotPass-title">
                        <p>
                            {send ? <> Пожалуйста, проверьте
                                ваш e-mail</>: <> Введите ваш email</>}
                        </p>
                    </div>
                    <div>
                        {send ? <>
                                Мы отправили письмо на ваш
                                электронный адрес для завершения
                                процесса восстановления пароля.
                            </> :
                        <>
                            <span className="error-message">{error}</span>
                            <input placeholder='Ваш email' value={email} onChange={(e) => setEmail(e.target.value)}
                                   type="email" className="unique-input"/>
                        </>
                        }
                    </div>
                    {!send &&
                        <div className="ForgotPass-button" id='red-button'>
                            <span onClick={() => handleClick()}>Отправить</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ForgotPass;